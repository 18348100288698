import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query"
import { pendingOrdersSaveDiagnosesUrl } from "applications/DoctorPendingOrderWorkflow/urls"
import { put } from "services/api"
import { UseSaveDiagnosesResponse, UseSaveDiagnosesMutation } from "./types"
import { ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"

export const useSaveDiagnoses = (
  pendingOrderId: string,
  therapySelectionId: string
): UseMutationResult<
  UseSaveDiagnosesMutation["Response"],
  UseSaveDiagnosesMutation["Error"],
  UseSaveDiagnosesMutation["Variables"],
  UseSaveDiagnosesMutation["Context"]
> => {
  const queryClient = useQueryClient()
  const queryKey = ["diagnoses", "selected", pendingOrderId, therapySelectionId]

  const mutationFn = ({ diagnoses }) =>
    put(pendingOrdersSaveDiagnosesUrl(pendingOrderId, therapySelectionId), {
      diagnoses,
    })

  const onMutate = async (newDiagnoses) => {
    await queryClient.cancelQueries({ queryKey })

    const previousDiagnosesSnapshot = queryClient.getQueryData<{
      diagnoses: string[]
    }>(queryKey)

    queryClient.setQueryData(queryKey, {
      diagnoses: newDiagnoses.diagnoses,
    })

    return { previousDiagnoses: previousDiagnosesSnapshot }
  }

  const onSuccess = async (response): Promise<UseSaveDiagnosesResponse> => {
    // todo: invalidate data/set new query data when we have queries for this
    return response.data
  }

  const onError = (error, _newDiagnoses, context) => {
    if (context?.previousDiagnoses) {
      queryClient.setQueryData(queryKey, context.previousDiagnoses)
    }

    handleError(error as ApplicationError)
  }

  return useMutation<
    UseSaveDiagnosesMutation["Response"],
    UseSaveDiagnosesMutation["Error"],
    UseSaveDiagnosesMutation["Variables"],
    UseSaveDiagnosesMutation["Context"]
  >({
    mutationFn,
    onMutate,
    onSuccess,
    onError,
  })
}
