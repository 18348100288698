import React from "react"
import { GridColDef } from "@mui/x-data-grid-pro"
import JoinTeamButton from "./JoinTeamButton"
import LeaveTeamButton from "./LeaveTeamButton"

const teamGridColumns = [
  {
    field: "name",
    headerName: "Team name",
    sortable: false,
    flex: 1,
  },
  {
    field: "members",
    headerName: "Members",
    sortable: false,
    valueGetter: (params) => params.row.members.join(" • "),
    flex: 1,
  },
  {
    field: "autoFollowScopes",
    headerName: "Auto-follow",
    sortable: false,
    valueGetter: (params) => {
      return params.row.autoFollowScopes.join(" • ")
    },

    flex: 1,
  },
]

const leaveTeamAction = {
  field: "actions",
  headerName: "Actions",
  sortable: false,
  renderCell: ({ row }) => {
    return <LeaveTeamButton teamId={row.id} teamName={row.name} />
  },
  flex: 0.5,
}

const joinTeamAction = {
  field: "actions",
  headerName: "Actions",
  sortable: false,
  renderCell: ({ row }) => {
    return <JoinTeamButton teamId={row.id} teamName={row.name} />
  },
  flex: 0.5,
}

export const myTeamsColumns: GridColDef[] = [
  ...teamGridColumns,
  leaveTeamAction,
]

export const otherTeamsColumns: GridColDef[] = [
  ...teamGridColumns,
  joinTeamAction,
]
