import React from "react"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import * as styles from "./StatCards.module.scss"
import { CanopyText } from "@parachutehealth/canopy-text"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import classNames from "classnames"

type Props = {
  cards: {
    count?: number
    subtitle: string
    title: string
    withLeftBorder?: boolean
  }[]
}

const StatCards = (props: Props) => {
  return (
    <CanopyFlex dir="row" gap="0" className={styles.container}>
      {props.cards?.map((card, index) => (
        <CanopyFlex
          key={index}
          inline
          direction="column"
          className={classNames([
            styles.card,
            index !== 0 && styles.withLeftBorder,
          ])}
        >
          <CanopyFlex
            className={styles.cardContent}
            paddingInline="12X"
            paddingBlock="8X"
            inline
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CanopyFlex direction="column" justifyContent="flex-start" gap="0">
              <CanopyText variant="primary" size="small" weight="bold">
                {card.title}
              </CanopyText>
              <CanopyText variant="secondary" size="xSmall">
                {card.subtitle}
              </CanopyText>
            </CanopyFlex>
            <CanopyHeading level={6} size="xLarge">
              {String(card.count || 0)}
            </CanopyHeading>
          </CanopyFlex>
        </CanopyFlex>
      ))}
    </CanopyFlex>
  )
}

export { StatCards }
