// @team @clinical-intelligence

import React, { useEffect, useState } from "react"
import {
  DocumentationRequirement,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import { Link, useHistory } from "react-router-dom"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { clinicalPath } from "applications/Workflow/routes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./supplier-page-header.module.scss"
import classnames from "classnames"

export type PdfSavingState = "unsaved" | "saving" | "saved"
type Props = {
  markComplete(): Promise<void>
  refreshDmeOrder(): Promise<void>
  documentationRequirement: DocumentationRequirement
  nextPagePath: string | undefined
  savingState: PdfSavingState
}

type DocumentationRequirementCompletionState =
  | "incomplete"
  | "makingAPICall"
  | "complete"

const SaveMessage = ({
  savingState,
  documentationRequirementCompletionState,
}: {
  savingState: PdfSavingState
  documentationRequirementCompletionState: DocumentationRequirementCompletionState
}) => {
  const completed = documentationRequirementCompletionState === "complete"
  if (savingState === "saving") {
    return (
      <div
        className={classnames({
          [styles.saveText]: true,
          [styles.completed]: completed,
        })}
      >
        <span>
          <CanopyIcon name="spinner" /> Saving...
        </span>
      </div>
    )
  }

  const savedText = completed ? "Saved and Completed" : "Saved"
  if (savingState === "saved") {
    return (
      <div
        className={classnames({
          [styles.saveText]: true,
          [styles.completed]: completed,
        })}
      >
        <span>
          <CanopyIcon name="check" /> {savedText}
        </span>
      </div>
    )
  }
  if (completed) {
    return (
      <div
        className={classnames({
          [styles.saveText]: true,
          [styles.completed]: completed,
        })}
      >
        <span>
          <CanopyIcon name="check" /> {savedText}
        </span>
      </div>
    )
  }
  return null
}

const initialDocumentationRequirementCompletionState = (
  satisfyStatus: DocumentationRequirementSatisfyStatus
) =>
  satisfyStatus === DocumentationRequirementSatisfyStatus.Complete
    ? "complete"
    : "incomplete"

// 1s from PdfViewer autosave delay plus 2s buffer
const DELAY_MS_TO_ENSURE_PDF_VIEWER_AUTO_SAVE = 3000

export const SupplierPageHeader = ({
  markComplete,
  refreshDmeOrder,
  documentationRequirement,
  nextPagePath,
  savingState,
}: Props) => {
  const [
    documentationRequirementState,
    setDocumentationRequirementState,
  ] = useState<DocumentationRequirementCompletionState>(
    initialDocumentationRequirementCompletionState(
      documentationRequirement.satisfyStatus
    )
  )
  const disabledButtons = savingState === "saving"

  useEffect(() => {
    setDocumentationRequirementState(
      initialDocumentationRequirementCompletionState(
        documentationRequirement.satisfyStatus
      )
    )
  }, [documentationRequirement.satisfyStatus])

  return (
    <div className={styles.headerGrid}>
      <h1 className={styles.header}>{documentationRequirement.title}</h1>
      <p className={styles.helperText}>
        {documentationRequirementState !== "complete" &&
          "Fill in the PDF and click ‘Mark Complete’ when finished."}
      </p>
      <SaveMessage
        savingState={savingState}
        documentationRequirementCompletionState={documentationRequirementState}
      />
      <MarkCompleteButton
        disabled={disabledButtons}
        markComplete={markComplete}
        refreshDmeOrder={refreshDmeOrder}
        nextPagePath={nextPagePath}
        documentationRequirementCompletionState={documentationRequirementState}
        setDocumentationRequirementCompletionState={
          setDocumentationRequirementState
        }
      />
      <ContinueButton disabled={disabledButtons} nextPagePath={nextPagePath} />
      <CanopyButton
        disabled={disabledButtons}
        aria-label="Close"
        className={`${styles.closeButton}`}
        to={clinicalPath()}
        as={Link}
        iconStart="xmark"
        size="small"
        variant="tertiary"
      />
    </div>
  )
}

const ContinueButton = ({
  nextPagePath,
  disabled,
}: {
  nextPagePath: string | undefined
  disabled: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const onClick = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      history.push(nextPagePath)
    }, DELAY_MS_TO_ENSURE_PDF_VIEWER_AUTO_SAVE)
  }

  return (
    <CanopyButton
      disabled={disabled}
      fullWidth
      onClick={onClick}
      loading={loading}
      role="button"
      size="small"
      variant="secondary"
      className={styles.continueButton}
    >
      Continue
    </CanopyButton>
  )
}

const MarkCompleteButton = ({
  disabled,
  markComplete,
  refreshDmeOrder,
  nextPagePath,
  documentationRequirementCompletionState,
  setDocumentationRequirementCompletionState,
}: {
  disabled: boolean
  markComplete(): Promise<void>
  refreshDmeOrder(): Promise<void>
  nextPagePath: string | undefined
  documentationRequirementCompletionState: DocumentationRequirementCompletionState
  setDocumentationRequirementCompletionState: (
    DocumentationRequirementCompletionState
  ) => void
}) => {
  const history = useHistory()

  const onClick = async () => {
    setDocumentationRequirementCompletionState("makingAPICall")
    try {
      const delayPromise = new Promise((resolve) =>
        setTimeout(resolve, DELAY_MS_TO_ENSURE_PDF_VIEWER_AUTO_SAVE)
      )
      await Promise.all([markComplete().then(refreshDmeOrder), delayPromise])
      setDocumentationRequirementCompletionState("complete")
      history.push(nextPagePath)
    } catch {
      return setDocumentationRequirementCompletionState("incomplete")
    }
  }

  if (documentationRequirementCompletionState === "complete") {
    return null
  }

  return (
    <div className={styles.markCompleteButton}>
      <CanopyButton
        disabled={disabled}
        fullWidth
        loading={documentationRequirementCompletionState === "makingAPICall"}
        loadingText=" "
        onClick={onClick}
        role="button"
        size="small"
        variant="primary"
      >
        Mark Complete
      </CanopyButton>
    </div>
  )
}
