import React from "react"
import { dmeOrderUrl } from "../../../../CarrierAuthorization/urls"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import {
  CarrierAuthorization,
  CarrierLineItemAuthorizationStatusEnum,
} from "../../../../../graphql/__generated__/graphql"

export interface SubmissionWarningsProps {
  carrierAuthorization: CarrierAuthorization
}

export const SubmissionWarnings = ({
  carrierAuthorization,
}: SubmissionWarningsProps): JSX.Element => {
  const hasPostSubmittedLineEdits = carrierAuthorization.carrierLineItemAuthorizations?.some(
    (lineItem) => {
      return (
        lineItem?.decisionStatus ===
        CarrierLineItemAuthorizationStatusEnum.EditsSaved
      )
    }
  )

  const hasEditedHcpcsCode = carrierAuthorization.carrierLineItemAuthorizations?.some(
    (lineItem) => {
      return lineItem?.editActions?.includes("edit_hcpcs")
    }
  )

  return (
    <>
      {hasPostSubmittedLineEdits && (
        <CanopyNotice
          title="Edits are saved, but still need to be submitted"
          message={`When you have completed making edits, click "Submit auth edits" at the bottom of the page`}
          variant="warning"
        />
      )}
      {hasEditedHcpcsCode && (
        <CanopyNotice
          className="canopy-mt-4x"
          title="The HCPCS you edited may require new clinical information"
          message="Clinical info can only be accessed from the order, so you’ll need to edit the cart on the original order to see if new clinical info is required"
          actions={[
            {
              href: dmeOrderUrl(carrierAuthorization?.dmeOrder?.id),
              text: "Go to order",
            },
          ]}
          variant="warning"
        />
      )}
    </>
  )
}
