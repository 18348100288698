import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierAuthPage_GetCarrierAuthorizationByIdQuery,
  CarrierAuthPage_GetCarrierAuthorizationByIdQueryVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierAuthPage_GetCarrierAuthorizationById: TypedDocumentNode<
  CarrierAuthPage_GetCarrierAuthorizationByIdQuery,
  CarrierAuthPage_GetCarrierAuthorizationByIdQueryVariables
> = gql`
  query CarrierAuthPage_GetCarrierAuthorizationById($id: String!) {
    carrierAuthorization(id: $id) {
      id
      authStatus
      createdAt
      requestSubmitDateTime
      submitFailed
      rankedDiagnoses {
        icd10Code {
          codeLabel
          description
        }
      }
      referralType
      sourceType
      orderingDoctor {
        name
        credential
        firstName
        lastName
        line1
        line2
        city
        state
        zip
        phoneNumber
        faxNumber
        email
        npi
        id
      }
      carrierLineItemAuthorizations {
        id
        authEditEligible
        decisionStatus
        deliveryAddress
        editActions
        endDate
        hcpcs
        hcpcsDescription
        hcpcsModifiers
        isActive
        isMiscHcpcs
        previousAuthorizationNumber
        priority
        priorAuthorizationNumber
        startDate
        supplierLegalEntity {
          name
        }
        quantity
      }
      orderingFacility {
        name
        line1
        line2
        city
        state
        zip
        phoneNumber
        faxNumber
        email
      }
      supplier {
        name
        npi
        phoneNumber
        line1
        line2
        city
        state
        zip
      }
      dmeOrder {
        id
        icd10Codes {
          codeLabel
          description
        }
        supplierLegalEntity {
          name
          npi
          phoneNumber
          line1
          line2
          city
          state
          zip
        }
      }
      carrierMembership {
        memberNumber
        personIdSuffix
        mbi
        altMemberNumber
        enrollmentStatus
        enrollmentStartDate
        enrollmentEndDate
        firstName
        lastName
        dateOfBirth
        sex
        relationToSubscriber
        line1
        line2
        city
        state
        zip
        phoneNumber
        groupNumber
        insuranceProduct {
          id
          planType
          name
        }
        planType
        planTypeCode
        cmsContractId
        cmsPlanId
        subscriber {
          firstName
          lastName
          dateOfBirth
          memberNumber
          altMemberNumber
          personIdSuffix
          mbi
          sex
          line1
          line2
          city
          state
          zip
          phoneNumber
        }
      }
      submitter {
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`
