import { useQuery, useQueryClient } from "@tanstack/react-query"

export const useGetSelectedDiagnoses = (
  pendingOrderId?: string,
  therapySelectionId?: string
) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ["diagnoses", "selected", pendingOrderId, therapySelectionId],
    queryFn: () => {
      // TODO: Replace with an actual API call in https://parachutehealth.atlassian.net/browse/WF-29980
      const data = queryClient.getQueryData<{ diagnoses: string[] }>([
        "diagnoses",
        "selected",
        pendingOrderId,
        therapySelectionId,
      ])
      return data || { diagnoses: [] }
    },
    enabled: !!pendingOrderId,
  })
}
