import { del, post } from "../../services/api"
import * as urls from "./urls"

export const createTeamMembership = (teamId) => {
  return post(urls.teamMembershipUrl(), { teamId })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}

export const destroyTeamMembership = (teamId) => {
  return del(urls.teamMembershipUrl(), { teamId })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
