import groupBy from "lodash/groupBy"
import { unique } from "utilities/array"

const groupLabel = (key) => {
  switch (key) {
    case "recommended":
      return "Recommended"
    case "fulfillment_agreement":
      return "Facilities on Parachute"
    case "global_npi_service":
      return "Other Facilities"
    default:
      return `Facilities from ${key}`
  }
}

const recommendedFacility = (facility, recommendedFacilities) =>
  recommendedFacilities.find((recommended) => recommended.id === facility.id)

export const groupedFacilities = (facilities, recommendedFacilities) => {
  facilities.forEach(
    (facility) =>
      (facility.group = recommendedFacility(facility, recommendedFacilities)
        ? "recommended"
        : facility.source)
  )

  const groups = groupBy(facilities, "group")
  return unique([
    "recommended",
    "fulfillment_agreement",
    ...Object.keys(groups),
  ])
    .filter((key) => groups[key])
    .map((key) => ({
      label: groupLabel(key),
      options: groups[key].map((result) => ({
        label: result.name,
        value: result.id,
        ...result,
      })),
    }))
}

const present = (value: string | undefined) => !!value && value.length > 0
const formatAddress = (
  address: {
    line1: string | undefined
    line2: string | undefined
    city: string | undefined
    state: string | undefined
    zip: string | undefined
  } = {
    line1: undefined,
    line2: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
  }
): string => {
  const combinedLine1and2 = [address.line1, address.line2]
    .filter(present)
    .join(" ")
  const combinedStateZip = [address.state, address.zip]
    .filter(present)
    .join(" ")
  const parts = [combinedLine1and2, address.city, combinedStateZip]
    .filter(present)
    .join(", ")
  return parts
}

export const formatNameAndAddress = (
  name: string | undefined,
  address: {
    line1: string | undefined
    line2: string | undefined
    city: string | undefined
    state: string | undefined
    zip: string | undefined
  }
) => {
  const formattedAddress = address ? formatAddress(address) : ""

  if (name && formattedAddress) {
    return `${name} - ${formattedAddress}`
  }

  if (name) {
    return `${name}`
  }

  if (formattedAddress) {
    return `${formattedAddress}`
  }

  return ""
}
