import React, { FC } from "react"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { destroyTeamMembership } from "./api"
import * as styles from "./styles.module.scss"
import { NOTICE_KEY } from "./index"

interface Props {
  teamId: string
  teamName: string
}

const LeaveTeamButton: FC<Props> = ({ teamId, teamName }) => {
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false)
  const [leaveTeamProcessing, setLeaveTeamProcessing] = React.useState(false)

  const handleClick = () => {
    setConfirmModalOpen(true)
  }

  const onClose = () => {
    setConfirmModalOpen(false)
  }

  const handleLeaveTeam = () => {
    setLeaveTeamProcessing(true)
    destroyTeamMembership(teamId).then(() => {
      sessionStorage.setItem(NOTICE_KEY, `Successfully left ${teamName}`)
      window.location.reload()
    })
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={styles.textOnlyButton}
        ref={activatorElementRef}
      >
        <strong style={{ color: canopyColorTextDanger }}>Leave team</strong>
      </button>
      <form onSubmit={handleLeaveTeam}>
        <CanopyDialogModal
          activatorElementRef={activatorElementRef}
          header={`Are you sure you want to leave ${teamName}?`}
          onClose={onClose}
          open={confirmModalOpen}
          primaryFooterButton={
            <CanopyButton
              data-testid="confirm-leave-team"
              onClick={handleLeaveTeam}
              type="submit"
              variant="danger"
              loading={leaveTeamProcessing}
            >
              Leave team
            </CanopyButton>
          }
          secondaryFooterButton={
            <CanopyButton onClick={onClose} variant="secondary">
              Cancel
            </CanopyButton>
          }
        >
          Leaving the team will remove you as a follower for any future incoming
          orders.
        </CanopyDialogModal>
      </form>
    </>
  )
}

export default LeaveTeamButton
