import React, { FC } from "react"
import { canopyColorBrandPrimary } from "@parachutehealth/canopy-tokens-color"
import { createTeamMembership } from "./api"
import * as styles from "./styles.module.scss"
import { NOTICE_KEY } from "./index"

interface Props {
  teamId: string
  teamName: string
}

const JoinTeamButton: FC<Props> = ({ teamId, teamName }) => {
  const handleClick = () => {
    createTeamMembership(teamId)
      .then(() => {
        sessionStorage.setItem(NOTICE_KEY, `Successfully joined ${teamName}`)
        window.location.reload()
      })
      .catch((error) => {
        console.error("Error creating team membership:", error)
      })
  }

  return (
    <button onClick={handleClick} className={styles.textOnlyButton}>
      <strong style={{ color: canopyColorBrandPrimary }}>Join team</strong>
    </button>
  )
}

export default JoinTeamButton
