// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__close-button--mk9Q3";
var _2 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__completed--Ruw40";
var _3 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__continue-button--Se1H0";
var _4 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__header--Bl39Q";
var _5 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__header-grid--KwuKS";
var _6 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__helper-text--LMqfk";
var _7 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__mark-complete-button--KCoBM";
var _8 = "app-javascript-applications-Workflow-containers-SupplierClinical-components-FillablePdfDocumentationRequirement-supplier-page-header-module__save-text--WLwzA";
export { _1 as "closeButton", _2 as "completed", _3 as "continueButton", _4 as "header", _5 as "headerGrid", _6 as "helperText", _7 as "markCompleteButton", _8 as "saveText" }
