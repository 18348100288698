import React, { useEffect, useState } from "react"
import { makeApi } from "applications/FacilitySelectForm/api"
import { patch } from "services/api"
import { FacilityOption } from "sharedTypes"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  CanopyComboboxField,
  OptionItem,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import * as styles from "./index.module.scss"
import * as utilities from "./utilities"
import { isComboBoxOptionItem } from "../helpers"
import { infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually } from "../../../queries"

export type InboxStateClinicalFacility = {
  facility?: {
    sgid: string
    name: string
    line1: string
    line2: string | undefined
    city: string
    state: string
    zip: string
  }
  extractedName?: string
  extractedAddress: {
    line1: string | undefined
    line2: string | undefined
    city: string | undefined
    state: string | undefined
    zip: string | undefined
  }
}
type UpdateFacilityInformation = (
  updatedData: Partial<InboxStateClinicalFacility>
) => Promise<void>

const facilityQueryKey = ["csrInboxState", "facility"]
export const setFacilityQueryData = (
  client: QueryClient,
  facilityData?: InboxStateClinicalFacility
) => {
  client.setQueryData(facilityQueryKey, facilityData)
}
export const useFacilityQuery = () => {
  return useQuery<InboxStateClinicalFacility>({
    queryKey: facilityQueryKey,
    queryFn: () => ({
      extractedAddress: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
      },
    }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
}

export type FacilityOptionItem = OptionItem & FacilityOption

export type FetchFacilities = (searchTerm: string) => Promise<FacilityOption[]>

const updateFacilityInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateFacilityInformation => async (
  updatedData: Partial<InboxStateClinicalFacility>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    facilityId: updatedData.facility?.sgid || null,
  }
  await patch(`${pathToCsrInboxState}/facility`, requestBody)
}

const optionRenderFunction = ({
  option,
}: {
  option: OptionItem
}): JSX.Element => {
  const facilityOption = option as FacilityOptionItem
  return (
    <div className={styles.supplierIntakeFacilityData}>
      <div>
        <strong>{facilityOption.name}</strong>
        <br />
        <span>
          {facilityOption.line1}
          {facilityOption.line2 ? " " + facilityOption.line2 : ""},{" "}
          {facilityOption.city}, {facilityOption.state}, {facilityOption.zip}
        </span>
      </div>
      <div> {facilityOption.userCount} Users</div>
    </div>
  )
}

export const FacilityData = ({}: {}) => {
  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()
  const { data } = useQuery<InboxStateClinicalFacility>({
    queryKey: ["csrInboxState", "facility"],
    queryFn: () => ({
      extractedAddress: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
      },
    }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })

  const labelForFacility = (
    facility:
      | {
          name: string
          line1: string
          line2: string | undefined
          city: string
          state: string
          zip: string
        }
      | undefined
  ) => {
    if (!facility) {
      return ""
    }
    return utilities.formatNameAndAddress(facility.name, { ...facility })
  }

  useEffect(() => {
    setSelectedValue(
      data?.facility
        ? {
            label: labelForFacility(data?.facility),
            value: data?.facility?.sgid,
          }
        : ""
    )
    setRecommendedFacilities([])
  }, [data?.facility])

  const [recommendedFacilities, setRecommendedFacilities] = useState(
    [] as FacilityOption[]
  )

  const onChange = updateFacilityInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const mutateFacility = useMutation({
    mutationFn: (facility: InboxStateClinicalFacility["facility"]) => {
      return onChange({ facility: facility }).then(() => {
        queryClient.setQueryData<InboxStateClinicalFacility>(
          facilityQueryKey,
          (old) => {
            return {
              extractedAddress: {
                line1: undefined,
                line2: undefined,
                city: undefined,
                state: undefined,
                zip: undefined,
              },
              ...old,
              facility: facility,
            }
          }
        )
      })
    },
  })

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const { data: options } = useQuery({
    queryKey: ["facilities", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchFacilities(searchTerm).then((results) => {
          return utilities.groupedFacilities(results, recommendedFacilities)
        })
      }
      return []
    },
  })

  const [selectedValue, setSelectedValue] = React.useState<SelectOption>()

  const fetchFacilities = makeApi("Supplier", csrInboxState.supplier.id)
    .fetchFacilities

  const facility = data || {
    extractedAddress: {
      line1: undefined,
      line2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
    },
  }
  const description = `Extracted text: ${utilities.formatNameAndAddress(
    facility.extractedName,
    facility.extractedAddress
  )}`

  return (
    <>
      <CanopyComboboxField
        placeholder="Type to search"
        label="Facility"
        options={options || []}
        multiple={false}
        value={selectedValue}
        onInputChange={setSearchTerm}
        optionRenderFunction={optionRenderFunction}
        onChange={(newValue) => {
          if (!newValue) {
            mutateFacility.mutateAsync(undefined)
            setSelectedValue("")
          } else if (isComboBoxOptionItem(newValue)) {
            const facilityOptionItem = newValue as FacilityOptionItem
            mutateFacility.mutateAsync({
              sgid: facilityOptionItem.value,
              ...facilityOptionItem,
            })
            setSelectedValue({
              label: labelForFacility(facilityOptionItem),
              value: newValue.value,
            })
          } else {
            console.warn(
              "new value on canopycomboboxfield was not an option item? That shouldn't happen",
              newValue
            )
          }
        }}
        noOptionsFoundMessage="No facilities found for that search"
        description={description}
      ></CanopyComboboxField>
    </>
  )
}
