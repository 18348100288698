// @team @expanse
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { FormikErrors } from "formik"
import { NewOrderType } from "sharedTypes"
import React from "react"

type OrderTypeModalProps = {
  open: boolean
  onClose: () => void
  onSubmit: (value: string) => Promise<void>
  orderType: NewOrderType | null
}

interface OrderTypeModalFields {
  orderTypeValue?: string
}

const OrderTypeModal = (props: OrderTypeModalProps): JSX.Element => {
  const { open, onClose, onSubmit, orderType } = props

  const orderTypeOptions = [
    {
      label: "No, they are getting a new setup",
      value: NewOrderType.Initial,
      description: "This is an initial order",
      defaultChecked: orderType === NewOrderType.Initial,
    },
    {
      label: "Yes, they currently use this",
      value: NewOrderType.Renewal,
      description: "This is a renewal order",
      defaultChecked: orderType === NewOrderType.Renewal,
    },
  ]

  const buttonCopy = orderType?.trim() ? "Save" : "Save & continue"

  const formik = useFormikParachute<OrderTypeModalFields>({
    initialValues: {
      orderTypeValue: "",
    },
    validate: (values) => {
      const errors: FormikErrors<OrderTypeModalFields> = {}
      if (!values.orderTypeValue) {
        errors.orderTypeValue = "Make a selection to continue"
      }
      return errors
    },
    onSubmit: (values) => {
      if (values.orderTypeValue) {
        onSubmit(values.orderTypeValue)
      }
    },
  })

  return (
    <CanopyDialogModal
      header="Does the patient currently use this product or service?"
      open={open}
      onClose={onClose}
      primaryFooterButton={
        <CanopyButton
          form="orderTypeModalForm"
          loadingText=""
          type="submit"
          loading={formik.isSubmitting}
        >
          {buttonCopy}
        </CanopyButton>
      }
    >
      This information helps us understand your needs better.
      <CanopyForm id="orderTypeModalForm" onSubmit={formik.handleSubmit}>
        <CanopyRadioInputField
          feedbackMessage={formik.errors.orderTypeValue}
          name="orderTypeValue"
          label=""
          onChange={formik.handleChange}
          options={orderTypeOptions}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default OrderTypeModal
