import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import {
  canopyColorTextPrimary,
  canopyColorTextSecondary,
} from "@parachutehealth/canopy-tokens-color"

type Props = {
  onClose: () => void
  visible: boolean
}

type LegendRowProps = {
  label: string
  body: string
}

const LegendRow = ({ label, body }: LegendRowProps) => {
  return (
    <div>
      <p
        className="canopy-typography-body-small canopy-typography-font-weight-bold canopy-mb-0"
        style={{ color: canopyColorTextPrimary }}
      >
        {label}
      </p>
      <p
        className="canopy-typography-body-small canopy-mb-0"
        style={{ color: canopyColorTextSecondary }}
      >
        {body}
      </p>
    </div>
  )
}

const SalesCollaborationToolsLegendModal = ({
  onClose,
  visible,
}: Props): React.JSX.Element => (
  <CanopyDialogModal
    header="Legend"
    onClose={onClose}
    open={visible}
    primaryFooterButton={
      <CanopyButton onClick={onClose} size="small" variant="primary">
        Done
      </CanopyButton>
    }
  >
    <p
      className="canopy-typography-body-xsmall"
      style={{ color: canopyColorTextSecondary }}
    >
      Note: The data displayed is aggregated across all suppliers you are
      currently employed with. Sorting by order data is only available when
      there are 25 or fewer facilities in the results.
    </p>
    <CanopyFlex gap="8X" direction="column">
      <LegendRow
        label="Joined Parachute"
        body="Elapsed time since a facility joined Parachute (starting from the BAA signing date)."
      />
      <LegendRow
        label="Avg. monthly orders (last 3 months)"
        body="Average number of orders per month based on the last 3 months."
      />
      <LegendRow
        label="Open orders"
        body="Orders that are NOT complete, canceled, or archived."
      />
      <LegendRow
        label="Orders (all-time)"
        body="Outstanding orders + completed orders of all time."
      />
      <LegendRow
        label="Sales rep(s)"
        body="This is the sales rep(s) assigned to the facility."
      />
      <LegendRow label="Address" body="This is the facility’s address." />
      <LegendRow
        label="Has ordered / Never ordered"
        body="Filters facilities based on whether or not they have completed an order with your suppliers."
      />
    </CanopyFlex>
  </CanopyDialogModal>
)

export { SalesCollaborationToolsLegendModal }
