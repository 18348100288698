import React, { useState } from "react"
import { datadogRum } from "@datadog/browser-rum"
import { PackageWithCartData } from "../sharedTypes"
import GridItem from "./GridItem"
import { CatalogBrowseTab, DmeOrder } from "sharedTypes"
import OrderTypeModal from "./OrderTypeModal"
import { submitOrderTypeChange } from "../api"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  currentCatalogBrowseTab: CatalogBrowseTab
  currentPage: number
  dmeOrder: DmeOrder
  onSelect(p: PackageWithCartData): Promise<void>
  packages: PackageWithCartData[]
  yourOrganizationsSuppliersOnly: boolean
  searchContextForRum?: Record<string, any>
}

const Grid = ({
  currentCatalogBrowseTab,
  currentPage,
  dmeOrder,
  packages,
  onSelect,
  yourOrganizationsSuppliersOnly,
  searchContextForRum = {},
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const [orderTypeModalOpen, setOrderTypeModalOpen] = useState(false)
  const [
    currentPackage,
    setCurrentPackage,
  ] = useState<PackageWithCartData | null>(null)
  const monitorAction = (pkg: PackageWithCartData) => {
    datadogRum.addAction(`click on Result on Page ${currentPage}`, {
      packageName: pkg.name,
      packageId: pkg.externalId,
      numberInCart: pkg.numberInCart,
      ...searchContextForRum,
    })
  }

  const onSelectGridItem = (pkg: PackageWithCartData) => {
    const showOrderTypeModal =
      isFeatureEnabled("orderTypeModal") && !dmeOrder.orderType
    setCurrentPackage(pkg)
    if (showOrderTypeModal) {
      setOrderTypeModalOpen(true)
    } else {
      monitorAction(pkg)
      onSelect(pkg)
    }
  }

  const onCloseOrderTypeModal = () => {
    setOrderTypeModalOpen(false)
  }

  const onSubmitOrderTypeModal = (orderTypeValue) => {
    return submitOrderTypeChange({ orderType: orderTypeValue }).then(() => {
      onCloseOrderTypeModal()
      if (currentPackage) {
        monitorAction(currentPackage)
        onSelect(currentPackage)
      }
    })
  }

  return (
    <div className="grid row gutter-0 display-flex">
      {packages.map((pkg) => (
        <GridItem
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          dmeOrder={dmeOrder}
          key={pkg.externalId}
          onSelect={async (pkg): Promise<void> => {
            onSelectGridItem(pkg)
          }}
          pkg={pkg}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      ))}
      <OrderTypeModal
        orderType={dmeOrder.orderType}
        open={orderTypeModalOpen}
        onClose={onCloseOrderTypeModal}
        onSubmit={onSubmitOrderTypeModal}
      />
    </div>
  )
}

export default Grid
